@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



body {
  margin: 0;
   font-family: "Cuprum", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content-title{
  color: rgb(128, 25, 25);
  font-family: "Cuprum", sans-serif;
  margin-top: 5rem !important;
  margin-bottom: 4rem !important;
}

table{
  color:rgb(128, 25, 25) ;
  background-color: rgb(210, 213, 210);
}
th{
  background-color: #151313;
}
tr:nth-child(even) {background-color: #f2f2f2;}
#nav-bar{
 
 background-repeat: no-repeat;
   background-position: center;
  background-size:cover ;  
  min-height: 75vh;
  width: 100%; 
  transition: background-image 3s ease-in-out;
}

#nav-bar1{
 
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 75vh;
  width: 100%; 
  transition: background-image 3s ease-in-out;
}

.team-cover{
  background-color: black;
  min-height: 75vh;
  opacity: 0.6;
}

#nav-ul{
  /* background-color: coral !important;  */
  background-color: #e5d0d0 !important;
  
  position: absolute;
   min-height: 100vh; 
  top: 0px;
  transition: 1s ease-in-out;

}

#banner-text{
 
  background-color: black;
  /* min-height: vh; */
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  /* align-items: center; */
  color: white;
  font-size: 3rem;
  font-weight: 800;
  text-transform: uppercase;
  opacity: 0.4;
}
 nav ul li{
  /* font-style: italic; */
  /* font-size: larger; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
 }

 #nav-data{
  font-style: italic;
  /* font-size: larger; */
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  
 }

 nav ul li:hover{
  /* font-style: italic; */
  font-size: larger;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: solid seagreen 0.2rem;
  font-weight: bold;
 }

 #myAbout h5{
  color: rgb(128, 25, 25);
 }

 nav ul li:active{
  font-style: italic;
  font-size: larger;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-bottom: solid seagreen 0.2rem;
 }


 #about-txt{
  /* padding-top: 3rem; */
  /* margin-top: 2rem; */
  margin-bottom: 3rem;
 
  font-family: "Roboto Condensed", sans-serif;
 }
 #about-img{
  display: inline-flex;
  width: 100px;

 }
 #about-design{
  display: flexbox;
  min-height: 100%;
  justify-content: baseline;
  padding-top: 8rem;
  margin-bottom: 0;

 }

 #about-design2{
  
  display: flexbox;
  min-height: auto;
  justify-content: baseline;

 }

 .abuot-img{
  width: 100%;
  height: 250px;
  
  
 }
 .about-img{
 width: 100%;
 }

 /* .service-txt{
  width: 100%;
  height: 85px;

  overflow: hidden;
  text-overflow: ellipsis;
 } */

 .service-txt p{
   width: 100%; 
  height: 75px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis; 
   
 }

 .service-head-text{
  color: rgb(128, 25, 25);
 }

 .card-img{
  width: 100%;
  height: 30vh;
 }

 #gmap_canvas{
  width: 100%;
  height: auto;
  min-height: 50vh;
 }

 .img-radius{
  border-radius: 5%;
 }

 .mapouter{
  text-align:right;
  height:294px;
  width:650px;}
.gmap_canvas{
  overflow:hidden;
  background:none!important;
  height:294px;
  width:650px;}


  .my-txt-center{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: red;
  }


.tracking-in-contract-bck:hover {
	-webkit-animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	        animation: tracking-in-contract-bck 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-7-14 17:44:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tracking-in-contract-bck
 * ----------------------------------------
 */
 @-webkit-keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes tracking-in-contract-bck {
  0% {
    letter-spacing: 1em;
    -webkit-transform: translateZ(400px);
            transform: translateZ(400px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    opacity: 1;
  }
}

.kenburns-top-left {
	-webkit-animation: kenburns-top-left 5s ease-out both;
	        animation: kenburns-top-left 5s ease-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-7-14 17:51:31
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top-left
 * ----------------------------------------
 */
 @-webkit-keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}
@keyframes kenburns-top-left {
  0% {
    -webkit-transform: scale(1) translate(0, 0);
            transform: scale(1) translate(0, 0);
    -webkit-transform-origin: 16% 16%;
            transform-origin: 16% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translate(-20px, -15px);
            transform: scale(1.25) translate(-20px, -15px);
    -webkit-transform-origin: top left;
            transform-origin: top left;
  }
}


.text-pop-up-top:hover {
	-webkit-animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: text-pop-up-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-7-14 18:12:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation text-pop-up-top
 * ----------------------------------------
 */
 @-webkit-keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}
@keyframes text-pop-up-top {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: none;
  }
  100% {
    -webkit-transform: translateY(-50px);
            transform: translateY(-50px);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    text-shadow: 0 1px 0 #cccccc, 0 2px 0 #cccccc, 0 3px 0 #cccccc, 0 4px 0 #cccccc, 0 5px 0 #cccccc, 0 6px 0 #cccccc, 0 7px 0 #cccccc, 0 8px 0 #cccccc, 0 9px 0 #cccccc, 0 50px 30px rgba(0, 0, 0, 0.3);
  }
}


#footer-top{
  border-top-right-radius: 10rem;
  margin-top: 3rem;
}


/* changing background images */

.background{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60vh;
  background-color: red;
  background-size: cover;
  background-position: center;
  transition: background-image 1s ease-in-out;
  
}

.service_img1{
  background-image: url('./images/service1.jpg');
  min-height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position:center;
  transition: transform 0.2s ease;
  /* margin-top: 2rem; */
}



.service_img2{
  background-image: url('./images/service2.jpg');
  min-height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position:center;
  transition: transform 0.2s ease;
  /* margin-top: 2rem; */
}
.img-zoom{
  transition: transform 0.2s ease;
}

.img-zoom:hover{
  transform: scale(1.1);
}

.service_img2:hover{
  transform: scale(1.1);
}

.service_img3:hover{
  transform: scale(1.1);
}
.service_img1:hover{
  transform: scale(1.1);
}
.service_img3{
  background-image: url('./images/service4.jpg');
  min-height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-position:center;
  transition: transform 0.2s ease;
  /* margin-top: 2rem; */
}

.service-card{
  background-color: brown;
  justify-content: center;
  align-items: center;
  
}
.service-head{
  color: white;
  padding-top: 3rem;
  font-size: 2rem;
  font-weight: 800;
  text-transform: uppercase;
}
.service-text{
  color: white;
  font-weight: 200;
  padding-top: 2rem;
   text-align: left; 
  position: relative;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
  padding: 3rem;
 

}

#advercon{
  background-color: rgb(106, 104, 104);
}

#contact-form{
  padding: 1rem;
  color:rgb(30, 34, 69) ;
  background-color: #e7e3e3;
  text-align: center;
}

#contact-map{
  margin-right:0rem ;
  padding: 1rem;
  color:rgb(30, 34, 69) ;
  text-align: center;
  min-height: 40vh;

}

.news-card{
  min-width: 200px;
  min-height:100px;
}

.news-card-img{
  width: auto;
  height: auto;
}

.news-text{
  text-align: left;
}

#news-main{
  max-height: 60vh;
  padding: 1rem;
  background-color: #f3eded;
}

#news_content{
  /* width: 100%; */
  height: 500px;
  /* white-space: nowrap; */
  overflow-y: auto;
  text-overflow: ellipsis;
}

.bg-slate-1000{
  background-color: #f3eded;
}

.text-ellipsis{
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.body-ellipsis{
  width: 100%;
  height: 50px;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.embed_video{
  width: 100%;
  height: 90vh;
}

#youtube_vd{

  /* width: 100%; */
  height: 80vh;
}

@media screen and (max-width: 768px) {

  #youtube_vd{

    /* width: 100%; */
    height: 40vh;
  }
  #contact-map{
   margin-bottom: 0.5rem;
  }


  #nav-bar{
 
    min-height: 55vh;
    width: 100%; 
    /* background-size: contain; */

  }
  .service_img1{
    margin-bottom: 4rem;
      
  }

  #banner-text{
    color: rgb(219, 83, 20);
    font-size: 2rem;
    font-weight: 800;
    text-transform: uppercase;
    text-align: center;
    
  }

  #nav-bar1{
 
    min-height: 55vh;
    /* background-size: contain; */
   
  }

  #news-main{
   /* background-color: yellowgreen; */
   /* background-color: #cccccc; */
   min-height: 65vh;
   padding-bottom: 2rem;
   border-bottom: dashed brown 0.5rem;
  }

  .news-divs{
    padding-top: 3rem;
   
  }

  .news-height{
    color: #cccccc;
  }
  .service_img1{
    /* background-size: contain; */
    /* margin-top: 2rem; */
  }
  
  .service_img2{
    /* background-size: contain; */
    /* margin-top: 2rem; */
  }
  .service_img3{
    /* background-size: contain; */
  }


  #about-design{
    display: none;
  
   }
  
   #about-design2{
    
    display: hidden;
  
   }

   #gmap_canvas{
   
    min-height: 40vh;
   }


   .card-img{
    height: 35vh;
   }
}